























import { Observer } from 'mobx-vue'
import { Component, Inject, Vue } from 'vue-property-decorator'
import { SearchViewModel } from '../viewmodels/search-viewmodel'

@Observer
@Component({
  components: {
    'post-grid': () => import('@/modules/community/common/components/post-grid.vue'),
    observer: () => import('vue-intersection-observer'),
  },
})
export default class PostGrid extends Vue {
  @Inject() vm!: SearchViewModel
  loadMore() {
    // if (this.vm.posts?.canLoadMorePost) {
    //   this.vm.posts.loadMore()
    // }
  }
}
